import React from 'react';
import {createUseStyles} from 'react-jss'

const sectionStyles = createUseStyles({
    sectionHeader: {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    sectionHeadline: {
      background: 'var(--font-color-gradient)',
      backgroundSize: 'cover',
      display: 'inline-block',
      fontSize: '2rem',
      fontWeight: '600',
      textFillColor: 'transparent',
      '-webkit-background-clip': 'text',
    },
    sectionSubline: {
        fontSize: '1.25rem',
        margin: '0 auto',
        maxWidth: '43.75rem',
    }
});

const SectionHeader = (props) => {
  const classes = sectionStyles();

  return (
    <div className={classes.sectionHeader}>
      <h2 className={classes.sectionHeadline}>
        {props.sectionHeadline}
      </h2>
      {props.sectionSubline ? 
      <p className={classes.sectionSubline}>
          {props.sectionSubline}
      </p>
      : null} 
    </div>
  );
}

export default SectionHeader;