import React from "react";
import { createUseStyles } from "react-jss";

const logoStyles = createUseStyles({
  logoStyle: {
    boxSizing: "border-box",
    fontWeight: "700",
  },
  logoName: {
    background: "var(--font-color-gradient)",
    backgroundSize: "cover",
    textFillColor: "transparent",
    "-webkit-background-clip": "text",
    fontSize: "1.5rem",
    paddingBottom: "3px",
    lineHeight: "1",
    "@media (min-width: 48rem)": {
      fontSize: "1.75rem",
    },
  },
  logoClaim: {
    background: "var(--font-color-gradient)",
    backgroundSize: "cover",
    textFillColor: "transparent",
    "-webkit-background-clip": "text",
    fontSize: ".75rem",
    "@media (min-width: 48rem)": {
      fontSize: "1rem",
    },
  },
  "@media (min-width: 48rem)": {
    logoStyle: {},
  },
});

const Logo = () => {
  const classes = logoStyles();
  return (
    <div className={classes.logoStyle}>
      <div className={classes.logoName}>Jochen Kärcher</div>{" "}
      <div className={classes.logoClaim}>Creative Concepts and Coding</div>
    </div>
  );
};

export default Logo;
