import React from 'react';
import { createUseStyles } from 'react-jss';

const skillStyles = createUseStyles({
  skill: {
    background: 'var(--background-box)',
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    listStyleType: 'none',
    padding: '1rem',
    '&:nth-child(2n+0)': {
      '& $skillIllustration': {
        '@media screen and (min-width: 62rem)': {
          order: '2',
        },
      },
    },
  },
  skillContainer: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
  },

  skillContent: {
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    flex: '0 1 100%',
    '@media screen and (min-width: 62rem)': {
      flex: '0 1 55%',
    },
  },
  skillTitle: {
    background: 'linear-gradient(230deg,  #008CC9, #009EA5)',
    backgroundSize: 'cover',
    textFillColor: 'transparent',
    '-webkit-background-clip': 'text',
    fontSize: '1.5em',
    fontWeight: '700',
    margin: '0 0 .5rem',
    padding: '0',
    textAlign: 'center',
    '@media screen and (min-width: 62rem)': {
      textAlign: 'left',
    },
  },
  skillDescription: {
    fontSize: '1.125rem',
  },
  skillIllustration: {
    flex: '0 1 100%',

    '@media screen and (min-width: 62rem)': {
      flex: '0 1 45%',
    },

    '& img': {
      display: 'block',
      height: 'auto',
      width: '100%',
    },
  },
});

const Skill = React.forwardRef((props, addToRefs) => {
  const classes = skillStyles();

  return (
    <li className={classes.skill} ref={addToRefs}>
      <div className={classes.skillContainer}>
        <div className={classes.skillIllustration}>
          <img
            src={props.skillIcon}
            height={300}
            width={400}
            alt={props.title}
          />
        </div>
        <div className={classes.skillContent}>
          <h3 className={classes.skillTitle}>{props.title}</h3>
          <div
            className={classes.skillDescription}
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
      </div>
    </li>
  );
});
export default Skill;
