import React from 'react';
import { createUseStyles } from 'react-jss';
import Contact from '../molecules/Contact';
import { Link } from 'react-router-dom';
import scrollTop from '../functions/scrollTop';

const footerStyles = createUseStyles({
  footer: {
    padding: '0 4% 1rem',
  },
  copyright: {
    padding: '1rem',
    textAlign: 'center',
  },
  copyrightLink: {
    background: 'yellow',
    color: 'var(--font-color-link-ext)',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  metaNav: {
    display: 'flex',
    justifyContent: 'center',
  },
  metaNavItem: {
    '&:first-child': {
      marginRight: '1rem',
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
});

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const classes = footerStyles();

  return (
    <footer className={classes.footer}>
      <Contact />
      <div className={classes.metaNav}>
        <div onClick={scrollTop} className={classes.metaNavItem}>
          <Link to='/privacy-policy'>Privacy Policy</Link>
        </div>
        <div onClick={scrollTop} className={classes.metaNavItem}>
          <Link to='/imprint'>Imprint</Link>
        </div>
      </div>
      <div className={classes.copyright}>
        © {year} Jochen Kärcher – Creative Concepts and Coding. Made with a
        keyboard, powered by{' '}
        <a
          rel='nofollow noreferrer'
          target='_blank'
          className={classes.copyrightLink}
          href='https://reactjs.org/'
        >
          React
        </a>
      </div>
    </footer>
  );
};
export default Footer;
