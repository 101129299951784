import React from 'react';
import SectionHeader from './SectionHeader';
import ContactLinks from './ContactLinks';

const Contact = () => {
  return (
    <section>
      <SectionHeader
        sectionHeadline='Contact me'
        sectionSubline="You would like to learn more about my work? Let's talk!"
      />
      <ContactLinks />
    </section>
  );
};

export default Contact;
