import React from 'react';
import { createUseStyles } from 'react-jss';
import ductTape from '../static/images/ducttape.svg';

const heroStyles = createUseStyles({
  hero: {
    background: 'var(--gradient-box)',
    margin: '0 auto 1.875rem',
    padding: '0',
  },
  heroContainer: {
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: '90rem',
    padding: '5.375rem 4% 0',
    '&::after': {
      content: '""',
      clear: 'both',
      display: 'table',
    },
    '@media screen and (min-width: 48rem)': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  heroImageContainer: {
    order: '1',
    float: 'right',
    padding: '1rem 0',
    '-webkit-backface-visibility': 'hidden',
    transform: 'rotateZ(-6deg)',
    width: '40%',
    '@media screen and (min-width: 48rem)': {
      padding: '2rem 0',
    },
  },
  heroImage: {
    display: 'block',
    height: 'auto',
    margin: '0 auto',
    maxWidth: '13.75rem',
    width: '100%',
  },
  ductTape: {
    maxWidth: '5rem',
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    '@media screen and (min-width: 48rem)': {
      maxWidth: '7.5rem',
    },
  },
  ductTapeUp: {
    top: '.625rem',
  },
  ductTapeDown: {
    bottom: '.625rem',
    transform: 'translateX(-50%) rotateZ(180deg)',
  },
  textContainer: {
    color: '#fff',
    fontSize: '1.25rem',
    fontWeight: '500',
    padding: '2rem 0',
    '@media screen and (min-width: 48rem)': {
      fontSize: '1.5rem',
      width: '60%',
    },
  },
  textLead: {
    fontSize: '1.5rem',
    fontWeight: '700',
    '@media screen and (min-width: 48rem)': {
      fontSize: '1.75rem',
    },
  },
  name: {},
  buttonCta: {
    '--moz-appearance': 'none',
    '--webkit-appearance': 'none',
    background: '#ff5e13',
    border: '0',
    borderRadius: '.25rem',
    color: 'inherit',
    cursor: 'pointer',
    fontSize: '1.15rem',
    fontWeight: '500',
    letterSpacing: '.05em',
    padding: '.75rem 1.25rem',
    textTransform: 'uppercase',
  },
});

const Hero = (props) => {
  const classes = heroStyles();
  return (
    <section className={classes.hero}>
      <div className={classes.heroContainer}>
        {props.heroImage ? (
          <div className={classes.heroImageContainer}>
            <img
              className={classes.ductTape + ' ' + classes.ductTapeUp}
              alt='Duct Tape'
              src={ductTape}
            />
            <picture>
              <source srcSet={props.heroImage} type='image/webp' />
              <source srcSet={props.heroImageFallback} type='image/jpeg' />
              <img
                className={classes.heroImage}
                height={800}
                width={555}
                src={props.heroImage}
                alt=''
              />
            </picture>
            <img
              className={classes.ductTape + ' ' + classes.ductTapeDown}
              alt='Duct Tape'
              src={ductTape}
            />
          </div>
        ) : null}
        <div className={classes.textContainer}>
          <h1 className={classes.textLead}>{props.heroFirstLine}</h1>
          <div
            className={classes.text}
            dangerouslySetInnerHTML={{ __html: props.heroText }}
          />
        </div>
      </div>
    </section>
  );
};
export default Hero;
