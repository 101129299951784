import React from "react";
import { NavLink } from "react-router-dom";
import { createUseStyles } from "react-jss";
import scrollTop  from "../functions/scrollTop"

const navStyles = createUseStyles({
  navList: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    margin: "0",
    padding: "1rem 0",
    "@media screen and (max-width: 47.99875rem)": {
      alignItems: "center",
      flexFlow: "column",
      height: "100%",
      justifyContent: "center",
      opacity: "0",
      position: "fixed",
      top: "0",
      left: "0",
      visibility: "hidden",
      width: "100%",
      "&.active": {
        background:
          "linear-gradient(230deg,  rgba(0,140,201,1), rgba(0,158,165,1))",
        opacity: "1",
        transition: "all .2s",
        visibility: "visible",
        zIndex: "5",
      },
    },
  },
  navItem: {
    margin: "0 1rem",
    listStyleType: "none",

    "@media screen and (min-width: 48rem)": {
      margin: '0 2rem 0 0',
      '&:last-child': {
        margin: '0 0 0 0'
      }
    }
  },
  navLink: {
    color: "#fff",
    fontSize: "3.5rem",
    letterSpacing: "0.05em",
    textDecoration: "none",
    "&.active": {
      textDecoration: "underline",
    },
    "@media screen and (min-width: 48rem)": {
      fontSize: "1.25rem",
      background: "var(--font-color-gradient)",
      backgroundSize: "cover",
      textFillColor: "transparent",
      "-webkit-background-clip": "text",
    },
  },
  mobileTriggerContainer: {
    alignItems: "center",
    display: "flex",
    height: "2rem",
    position: "relative",
    width: "2rem",
    zIndex: "12",
    "@media screen and (min-width: 48rem)": {
      display: "none",
    },
    "&::after": {
      display: "block",
      content: '""',
      height: "100%",
      left: "0",
      position: "absolute",
      top: "0",
      width: "100%",
    },
    "&.active $mobileTrigger": {
      background: "transparent",
      transition: "all .25s",
      "&::before": {
        background: "#fff",
        transition: "all .25s",
        transform: "translateY(-8px) rotateZ(-45deg)",
      },
      "&::after": {
        background: "#fff",
        transition: "all .25s",
        transform: "translateY(12px) rotateZ(45deg)",
      },
    },
  },
  mobileTrigger: {
    background: "var(--font-color-gradient)",
    height: "3px",
    textIndent: "-9999px",
    transition: "all .25s",
    width: "100%",
    "&::before": {
      background: "var(--font-color-gradient)",
      content: '""',
      display: "block",
      height: "3px",
      position: "absolute",
      bottom: "5px",
      transition: "all .25s",
      width: "100%",
    },
    "&::after": {
      background: "var(--font-color-gradient)",
      content: '""',
      display: "block",
      height: "3px",
      position: "absolute",
      top: "5px",
      transition: "all .25s",
      width: "100%",
    },
  },
});

const navTrigger = (e) => {
  e.target.classList.toggle("active");
  document.getElementById("navList").classList.toggle("active");
};

const navTriggerRemove = () => {
  scrollTop();
  document.getElementById("navTrigger").classList.remove("active");
  document.getElementById("navList").classList.remove("active");
};

const Nav = () => {
  const classes = navStyles();
  return (
    <nav className={classes.nav}>
      <ul id="navList" className={classes.navList} data-nav-list>
        <li className={classes.navItem}>
          <NavLink
            onClick={navTriggerRemove}
            className={classes.navLink}
            exact
            to="/"
            data-nav-link
          >
            About
          </NavLink>
        </li>
        <li className={classes.navItem}>
          <NavLink
            onClick={navTriggerRemove}
            className={classes.navLink}
            to="/projects"
            data-nav-link
          >
            Projects
          </NavLink>
        </li>
        <li className={classes.navItem}>
          <NavLink
            onClick={navTriggerRemove}
            className={classes.navLink}
            to="/vitae"
            data-nav-link
          >
            Vitae
          </NavLink>
        </li>
      </ul>
      <div
        id="navTrigger"
        onClick={navTrigger}
        className={classes.mobileTriggerContainer}
      >
        <div className={classes.mobileTrigger}>Menu</div>
      </div>
    </nav>
  );
};

export default Nav;
