import React from 'react';
import { createUseStyles } from 'react-jss';
import './index.css';
import Header from './Sections/Header';
import Footer from './Sections/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import fontsData from './data/fonts.json';
const Projects = React.lazy(() => import('./pages/Projects'));
const FourNullFour = React.lazy(() => import('./pages/FourNullFour'));
const Imprint = React.lazy(() => import('./pages/Imprint'));
const Vitae = React.lazy(() => import('./pages/Vitae'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));

const appStyle = createUseStyles({
  '@global': {
    '@font-face': [
      {
        fontDisplay: 'swap',
        fontFamily: '"Source Sans Pro"',
        fontWeight: '300',
        src: 'url(' + fontsData[0].file + ')',
      },
      {
        fontDisplay: 'swap',
        fontFamily: '"Source Sans Pro"',
        fontWeight: '700',
        src: 'url(' + fontsData[1].file + ')',
      },
    ],
    ':root': {
      '--background-website': 'rgba(255,255,255,1)',
      '--background-box': 'rgba(255,255,255,1)',
      '--font-color-typo': 'rgba(0,0,0,1)',
      '--font-color-link-ext': 'rgba(0,0,0,1)',
      '--font-color-gradient':
        'linear-gradient(230deg,  rgba(0,140,201,1), rgba(0,158,165,1))',
      '--gradient-box':
        'linear-gradient(230deg,  rgba(0,140,201,1), rgba(0,158,165,1))',
      '@media (prefers-color-scheme: dark)': {
        '--background-website': '#121212',
        '--background-box': 'rgba(255,255,255,.15)',
        '--font-color-typo': 'rgba(255,255,255,1)',
        '--font-color-link-ext': 'rgba(0,0,0,1)',
        '--gradient-box':
          'linear-gradient(230deg,  rgba(0,140,201,.5), rgba(0,158,165,.5))',
      },
    },
    body: {
      background: 'var(--background-website)',
      fontFamily: '"Source Sans Pro"',
      fontDisplay: 'swap',
      fontWeight: '300',
    },
  },

  app: {
    color: 'var(--font-color-typo)',
  },
});

const App = () => {
  const classes = appStyle();
  return (
    <div className={classes.app}>
      <React.Suspense fallback={<p>Loading...</p>}>
        <Router>
          <Header />
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/projects'>
              <Projects />
            </Route>
            <Route path='/imprint'>
              <Imprint />
            </Route>
            <Route path='/vitae'>
              <Vitae />
            </Route>
            <Route path='/privacy-policy'>
              <PrivacyPolicy />
            </Route>
            <Route component={FourNullFour} />
          </Switch>
          <Footer />
        </Router>
      </React.Suspense>
    </div>
  );
};

export default App;
