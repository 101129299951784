import React, { useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import SectionHeader from '../molecules/SectionHeader';
import Skill from '../molecules/Skill';
import services from '../data/services';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const skillsStyles = createUseStyles({
  skills: {
    position: 'relative',
  },
  skillsContainer: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: '1rem',
    margin: '0 auto',
    maxWidth: '60rem',
    padding: '0 4% 3.75rem',

    '@media screen and (min-width: 48rem)': {
      gridColumnGap: '2rem',
      gridRowGap: '2rem',
    },
  },
});

const Skills = () => {
  const classes = skillsStyles();
  gsap.registerPlugin(ScrollTrigger);

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
    revealRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          transform: 'translateY(-20px)',
        },
        {
          duration: 0.5,
          autoAlpha: 1,
          ease: 'none',
          transform: 'translateY(0)',
          scrollTrigger: {
            trigger: el,
            id: `section-${index + 1}`,
            start: 'top bottom-=80',
            toggleActions: 'play none none none',
          },
        }
      );
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  return (
    <section className={classes.skills}>
      <SectionHeader sectionHeadline='What I do' />
      <ul className={classes.skillsContainer}>
        {services.map((service) => (
          <Skill
            key={service.id}
            ref={addToRefs}
            skillIcon={service.iconUrl}
            title={service.title}
            description={service.text}
          />
        ))}
      </ul>
    </section>
  );
};
export default Skills;
