import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../Sections/Hero';
import HeroData from '../data/hero.json';
import Skills from '../Sections/Skills';

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Jochen Kärcher – Creative Concepts and Coding</title>
        <meta
          name='description'
          content='I’m a passionate developer with over 16 years of work experience specialized on frontend development, design and information architecture.'
        />
        <link rel='canonical' href='https://jochenkaercher.eu' />
      </Helmet>
      <Hero
        heroFirstLine={HeroData[0].firstLine}
        heroText={HeroData[0].text}
        heroImage={HeroData[0].imageUrl}
        heroImageFallback={HeroData[0].imageFallbackUrl}
      />
      <Skills />
    </>
  );
};

export default Home;
