import React from 'react';
import { createUseStyles } from 'react-jss';
import Nav from '../molecules/Nav';
import Logo from '../atoms/Logo';

const headerStyles = createUseStyles({
  header: {
    boxShadow: '0 0 .75rem rgba(43,43,43,0)',
    boxSizing: 'border-box',
    margin: '0 auto',
    position: 'fixed',
    top: '0',
    transition: 'all .4s',
    left: '0',
    width: '100%',
    zIndex: '10',
    '&:not(.active)': {
      '--font-color-gradient': '#fff',
    },
    '&.active': {
      boxShadow: '0 0 .75rem rgba(43,43,43,.25)',
      background: 'var(--background-website)',
      transition: 'all .4s',
    },
  },
  headerContainer: {
    alignItems: 'center',
    background: 'transparent',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: '90rem',
    padding: '.25rem 4%',

    '@media screen and (min-width: 48rem)': {
      alignItems: 'flex-start',
      padding: '1rem 4%',
    },
  },
});
const activeHeader = () => {
  document.addEventListener('scroll', function () {
    if (window.pageYOffset >= 1) {
      document.getElementById('header').classList.add('active');
    } else {
      document.getElementById('header').classList.remove('active');
    }
  });
};

const Header = () => {
  const classes = headerStyles();

  activeHeader();

  return (
    <header id='header' className={classes.header}>
      <div className={classes.headerContainer}>
        <Logo />
        <Nav />
      </div>
    </header>
  );
};

export default Header;
