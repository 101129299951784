import React from 'react';
import { createUseStyles } from 'react-jss';

const contactLinksStyles = createUseStyles({
  contactLinksList: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    margin: '-1rem 0 5.625rem',
  },
  contactLinksItem: {
    listStyleType: 'none',
    marginRight: '1rem',
    '&:last-child': {
      marginRight: '0',
    },
  },
  contactLinksLink: {
    background: 'yellow',
    color: 'var(--font-color-link-ext)',
    textDecoration: 'none',
  },
});

const ContactLinks = () => {
  const classes = contactLinksStyles();
  return (
    <ul className={classes.contactLinksList}>
      <li className={classes.contactLinksItem}>
        <a
          className={classes.contactLinksLink}
          rel='nofollow noopener noreferrer'
          href='mailto:info@jochenakercher.eu'
        >
          email
        </a>
      </li>
      <li className={classes.contactLinksItem}>
        <a
          className={classes.contactLinksLink}
          rel='nofollow noopener noreferrer'
          target='_blank'
          href='https://www.linkedin.com/in/jochenkaercher'
        >
          linkedin
        </a>
      </li>
      <li className={classes.contactLinksItem}>
        <a
          className={classes.contactLinksLink}
          rel='nofollow noopener noreferrer'
          target='_blank'
          href='https://www.xing.com/profile/Jochen_Kaercher4'
        >
          xing
        </a>
      </li>
      <li className={classes.contactLinksItem}>
        <a
          className={classes.contactLinksLink}
          rel='nofollow noopener noreferrer'
          target='_blank'
          href='https://twitter.com/jochenkaercher'
        >
          twitter
        </a>
      </li>
      <li className={classes.contactLinksItem}>
        <a
          className={classes.contactLinksLink}
          rel='nofollow noopener noreferrer'
          target='_blank'
          href='https://www.drupal.org/u/joka84'
        >
          drupal.org
        </a>
      </li>
    </ul>
  );
};

export default ContactLinks;
